import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'

class HomeIndex extends React.Component {
    render() {
      const { edges } = this.props.data.allContentfulProject;
        return (
            <Layout links={edges}>
              <Helmet
                title="Edward Beazer Portfolio"
                meta={[
                  { name: 'description', content: 'Sample' },
                  { name: 'keywords', content: 'sample, something' },
                ]}
              >
              </Helmet>
              <Banner />
              <div id="main" >
                <section id="one" className="tiles">
                  {edges.map(project => {
                    return (
                      <article
                        style={{backgroundImage: `url(${project.node.cover.fluid.src})`}}
                        key={project.node.id}
                      >
                        <header className="major">
                          <h3>{project.node.title}</h3>
                          <p>{project.node.shortDescription}</p>
                        </header>
                        <Link to={`/${project.node.slug}`} className="link primary"></Link>
                      </article>
                    );
                  })}
                </section>
              </div>
            </Layout>
        )
    }
}

export const landingPage = graphql`
  query {
    allContentfulProject(
      sort: {
        fields: [order]
        order: ASC
      }
    ) {
      edges {
        node {
          id
          title
          shortDescription
          slug
          cover {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`

export default HomeIndex
