import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
      <div className="inner">
        <header className="major">
          <h1>Hi, my name is Edward</h1>
        </header>
        <div className="content">
          <p>Below are some of my current and finished projects.<br />
            All of my projects use React as a <br />frontend and NodeJS/serverless backends.
          </p>
          <ul className="actions">
            <li><a href="#one" className="button next scrolly">Get Started</a></li>
          </ul>
        </div>
      </div>
    </section>
)

export default Banner
